var resourcesInterval = 0;

var isLocal = ENV === 'local';

var resourcesReloadDelayMinutes = isLocal ? 10 : 5;
var resourcesReloadDelay = resourcesReloadDelayMinutes * 60 * 1000; //minutes * seconds * miliseconds

const admin_role = 'admin';
const moderator_role = 'moderator';

var Methods = {
    getResources: function (vm, vmHttp, callback, showLoader) {
        // if (isLocal) {
        //     callback();
        //     return;
        // }

        showLoader = (typeof showLoader === 'undefined') ? true : showLoader;

        //products
        if (showLoader) {
            Loader.text('Wczytywanie produktów...');
        }

        Methods.log('Wczytywanie zasobów');

        vmHttp.get('/products/min-list').then(function (response) {
            vm.products = response.data;
            storage.products = response.data;

            //contractors
            if (showLoader) {
                Loader.text('Wczytywanie kontrahentów...');
            }

            vmHttp.get('/contractors').then(function (response) {
                vm.contractors = response.data;
                storage.contractors = response.data;

                if (showLoader) {
                    Loader.text('');
                }
                callback();
            }, function (response) {
                Methods.errorHandler(vm, response);
            });
        }, function (response) {
            Methods.errorHandler(vm, response);
        });
    }, refreshResources: function (vm, vmHttp) {
        Methods.log('Odświeżanie zasobów');

        Methods.getResources(vm, vmHttp, function () {
        }, false);
    }, setResourcesInterval: function (vm, vmHttp) {
        Methods.log('Ustawienie interwału odświeżania zasobów');

        resourcesInterval = setInterval(function () {
            Methods.getResources(vm, vmHttp, function () {
            }, false);
        }, resourcesReloadDelay);
    }, clearResourcesInterval: function () {
        Methods.log('Zerowanie interwału odświeżania zasobów');

        clearInterval(resourcesInterval);
    },

    log: function (message) {
        if (!isLocal) return;

        function checkTime(i) {
            if (i < 10) {
                i = "0" + i;
            }
            return i;
        }

        var date = new Date();

        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();

        minutes = checkTime(minutes);
        seconds = checkTime(seconds);

        var time = ([hours, minutes, seconds]).join(':');

        console.log(time + ' ' + message);
    },

    storage: {
        set: function (name, data) {
            localStorage.setItem(name, JSON.stringify(data));
        }, get: function (name) {
            const data = localStorage.getItem(name);
            return JSON.parse(data);
        }, remove: function (name) {
            localStorage.removeItem(name);
        }, isUpdated: function () {
            return true; //TODO
        }, clear: function () {
            localStorage.clear();
        }
    },

    session: {
        set: function (name, data) {
            sessionStorage.setItem(name, JSON.stringify(data));
        }, get: function (name) {
            const data = sessionStorage.getItem(name);
            return JSON.parse(data);
        }, remove: function (name) {
            sessionStorage.removeItem(name);
        }, clear: function () {
            sessionStorage.clear();
        }
    },

    readBarCode: function (callback, focusOut) {
        focusOut = focusOut || false;
    },

    errorHandler: function (vm, response, redirect) {
        redirect = redirect || false;

        if (isLocal) {
            console.log(response);
        }

        //need auth
        if (response.status == 401) {
            // vm.$dispatch('userHasLoggedOut');
            //server 500
        } else if (response.status == 500 || response.status == 0) {
            Messages.error(response.data.error || response.data.message || 'Błąd krytyczny. Skontaktuj się z administratorem.');
            //token mismatch
        } else if (response.status == 498) {
            Messages.refresh('Sesja wygasła. Odśwież stronę.');
            //form validation
        } else if (response.status == 400) {
            //display message
            Messages.error('Wystąpiły błędy formularza.');

            var validation_errors = {};

            try {
                validation_errors = jQuery.parseJSON(response.data);
            } catch (e) {
                validation_errors = response.data.validation_errors;
            }

            Methods.handleValidationErrors(validation_errors);

        } else if (response.status == 422) {
            if (response.data.error === 'incorrect_pin') {
                Messages.error('Nieprawidłowy PIN');
                Methods.storage.remove('user_pin');
                $('.sweet-alert input[type="password"]').val('');
            } else {
                Messages.info(vm.$t('common.errors.' + response.data.error));
            }
        } else {
            if (response.status == 404) {
                if (redirect) {
                    vm.$route.router.go(window.history.back());
                }

                Messages.info(response.data.error);
            } else if (response.status == 403) {
                let text = 'Nie posiadasz wystarczających uprawnień.';

                if (response.data && response.data.error_description) {
                    const translation = vm.$t('common.permissions.' + response.data.error_description)

                    if (translation) {
                        text = translation;
                    }
                }

                Messages.info(text);
            } else {
                Messages.info(response.data);
            }
        }

        //hide loader
        Loader.off();
    },

    handleValidationErrors(validation_errors) {
        //hide all errors
        $('.form-control').parents('.form-group').removeClass('has-error');
        $('.has-error').find('span.help-block').remove();
        $('#itemsTable').parent().find('.alert').remove();
        $('#itemsWrapper [data-product-id]').removeClass('error');

        //loop through response errors and show them near the appropriate form fields
        $.each(validation_errors, function (field_name, errors) {
            field_name_splitted = field_name.split('.');
            field_name = field_name_splitted[0] === 'values' || field_name_splitted[0] === 'purchaseData' ? field_name_splitted[1] : field_name_splitted[0];

            field = $('#' + field_name);

            if (field.length == 0) {
                field = $('[name="' + field_name + '"]');

                if (field.is(':radio')) {
                    field.parents('.form-group').addClass('has-error');
                }
            }

            field.parents('.form-group').addClass('has-error');
            var errors_array = [];

            $.each(errors, function (rule, params) {
                var parseAll = ['In'].indexOf(rule) > -1 ? true : false;
                ruleString = Methods.parseString(lang.validation[rule], params, parseAll);
                errors_array.push(ruleString);
            });

            var errors_string = errors_array.join(' ');

            if (field_name === 'items') {
                var errorText = null;
                $('#itemsError').remove();

                if (typeof (errors.Required) !== 'undefined') {
                    errorText = 'Wymagana co najmniej jedna pozycja. Wszystkie pola wymagane. Ceny i podsumy nie mogą być zerowe. Dla paragonu nie można dodać produktów ze zwolnionym VATem.';
                }

                if (typeof (errors.ItemsQuantities) !== 'undefined') {
                    errorText = '<b>Brak wystarczającej liczby produktów. Dostępne stany:</b><br/><ul>';

                    $.each(errors.ItemsQuantities, function (index, item) {
                        errorText += '<li>"' + item.name + '": <b>' + item.availableAmount + '</b></li>';
                    });

                    errorText += '</ul>';
                }

                if (errorText) {
                    if ($('#itemsWrapper').length) {
                        $('#itemsWrapper').prepend('<div class="alert alert-danger" id="itemsError">' + errorText + '</div>');

                        $.each(errors.ItemsQuantities, function (index, item) {
                            $('#itemsWrapper [data-product-id="' + item.id + '"]').addClass('error');
                        });
                    } else {
                        $('#itemsTable').parent().prepend('<div class="alert alert-danger" id="itemsError">' + errorText + '</div>');
                    }
                }
            } else if (field.parent().hasClass('input-group') || field.is(':radio')) {
                field.parent().parent().append('<span class="help-block">' + errors_string + '</span>');
            } else {
                field.parent().append('<span class="help-block">' + errors_string + '</span>');
            }
        });
    },

    parseString: function (string, params, parseAll) {
        parseAll = parseAll || false;

        if (parseAll) {
            params = params.join(', ');
            string = string.replace('{0}', params);
        } else {
            for (i = 0; i < params.length; i++) {
                var substr = '{' + i + '}';
                if (string) {
                    if (string.indexOf(substr) >= 0) {
                        var replace = lang.fields[params[i]] ? lang.fields[params[i]] : params[i];
                        string = string.replace(substr, replace);
                    }
                }
            }
        }

        return string;
    },

    //capitalize first letter
    capitalizeFirstLetter: function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    numbers: {
        format: function (value) {
            return parseFloat(Math.round(value * 100) / 100).toFixed(2);
        },

        prettify: function (value) {
            var num = Methods.numbers.format(value);
            return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ');
        },

        decimalPlaces: function (value) {
            var match = ('' + value).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
            if (!match) {
                return 0;
            }
            return Math.max(0, // Number of digits right of decimal point.
              (match[1] ? match[1].length : 0)
              // Adjust for scientific notation.
              - (match[2] ? +match[2] : 0));
        }
    },

    objects: {
        clone: function (toClone) {
            return JSON.parse(JSON.stringify(toClone));
        },
    },

    arrays: {
        find: function (array, property, value) {
            return $.grep(array, function (item) {
                return item[property] == value;
            });
        },

        findById: function (array, id) {
            const result = Methods.arrays.find(array, 'id', id);
            return result ? result[0] : null;
        },

        getIndex: function (array, property, value) {
            for (var i = 0; i < array.length; i++) {
                if (array[i][property] !== undefined) {
                    if (array[i][property] == value) {
                        return i;
                    }
                } else {
                    if (array[i] == value) {
                        return i;
                    }
                }
            }
            return null;
        },

        getNested: function (array, property) {
            var params = property.split('.');

            if (params[0] !== undefined) {
                if (params[1] !== undefined) {
                    return Methods.arrays.getNested(array[params[0]], params[1]);
                } else {
                    return array[params[0]];
                }
            } else {
                return null;
            }
        },

        lists: function (array, property) {
            result = [];
            $.each(array, function (i, item) {
                result.push(item[property]);
            });
            return result;
        },

        add: function (array, item, atStart) {
            atStart = atStart || false;

            if (atStart) {
                array.unshift(item);
            } else {
                array.push(item);
            }
        },

        update: function (array, item) {
            Methods.arrays.updateBy(array, 'id', item);
        },

        updateBy: function (array, property, item) {
            var index = Methods.arrays.getIndex(array, property, item[property]);
            array[index] = item;
        },

        deleteBy: function (array, property, value) {
            var index = Methods.arrays.getIndex(array, property, value);
            Methods.arrays.delete(array, index);
        },

        deleteById: function (array, id) {
            Methods.arrays.deleteBy(array, 'id', id);
        },

        delete: function (array, index) {
            array.splice(index, 1);
        },
    },

    //init scripts
    init: function () {
        setTimeout(function () {
            $('.dropdown-toggle').dropdown();

            $('html').removeClass('sidebar-active');
            $('.hamburger-toggle').unbind('click');
            $('.hamburger-toggle').on('click', function (e) {
                e.preventDefault();
                $('html').toggleClass('sidebar-active');
            });

            //table inputs events
            $('body').on('focusin', '.table-a input', function () {
                $(this).parents('tr').addClass('hovered');
            });
            $('body').on('focusout', '.table-a input', function () {
                $(this).parents('tr').removeClass('hovered');
            });

            //handle number fields
            $('body').on('keypress', '.field-decimal', function (e) {
                //insert dot if pressed comma
                if (e.which == 44) {
                    if ($(this).val().indexOf('.') == -1) {
                        $(this).val($(this).val() + '.');
                    }
                }

                //prevent if not number/dot
                if (e.which < 46 || e.which > 59) {
                    e.preventDefault();
                }

                //prevent if already dot
                if (e.which == 46 && $(this).val().indexOf('.') != -1) {
                    e.preventDefault();
                }
            });

            $('body').on('paste', '.field-decimal', function (e) {
                e.preventDefault();
            });

            $('body').on('keypress', '.table-a tbody input', function (e) {
                if (e.which == 13) {
                    //validate
                    inputs = $(this).parents('tr').find('input[type="text"]:not(.chosen-search-input)');
                    empty = [];
                    $.each(inputs, function (index, input) {
                        value = $(input).val();
                        if (value == '' || value == 0.00) {
                            empty.push(input);
                        }
                    });

                    if (empty.length > 0) {
                        $(empty[0]).focus();
                    } else {
                        $('#products_search').focus();
                    }
                }
            });

            //prevent clicking disabled anchors
            $('a[disabled]').on('click', function (e) {
                e.preventDefault();
            });

            //
            $('#sidebar nav > ul > li').each(function () {
                var item_anchor = $(this).children('a');
                var submenu = $(this).find('ul');

                if (submenu.length > 0) {
                    var subitems = submenu.children('li');

                    if (subitems.length > 0) {
                        var first_item_anchor = $(subitems[0]).children('a');
                        // item_anchor.prop('href', first_item_anchor.attr('href'));

                        if (subitems.length == 1) {
                            item_anchor.children('span').text(first_item_anchor.text());
                            $(subitems[0]).remove();
                        }
                    }
                }
            });

            // $('body').tooltip({
            //     selector: '[rel="tooltip"]'
            // });

            // $('[rel="tooltip"]').tooltip();

            $('body').removeClass('header-a-fixed');
            $('body').removeClass('header-panel-a-fixed');
        }, 100);
    }
};

var Loader = {
    on: function (text = 'Wczytywanie') {
        $('#main-loader').addClass('on');
        $('#main-loader').find('.text').text(text + '...');

        // $('html').addClass('stop-scrolling');
    }, off: function () {
        $('#main-loader').removeClass('on');
        $('#main-loader').find('.text').text('');

        // $('html').removeClass('stop-scrolling');
    }, text: function (text) {
        $('#main-loader').find('.text').text(text);
    }
}

var Messages = {
    success: function (text, turnOffTimer) {
        turnOffTimer = turnOffTimer || false;
        timer = turnOffTimer ? null : 1000;
        showConfirmButton = turnOffTimer ? true : false;
        allowOutsideClick = turnOffTimer ? false : true;
        swal({
            title: 'Sukces',
            text: text,
            type: 'success',
            timer: timer,
            showConfirmButton: showConfirmButton,
            allowOutsideClick: allowOutsideClick,
            animation: 'pop',
            html: true
        });
    }, info: function (text, time) {
        timer = time ? time : null;
        swal({
            title: 'Ostrzeżenie',
            text: text,
            type: 'warning',
            timer: timer,
            html: true,
            showConfirmButton: true,
            confirmButtonText: 'OK',
            animation: 'pop'
        });
    }, error: function (text, time) {
        timer = time ? time : null;
        swal({
            title: 'Błąd',
            text: text,
            type: 'error',
            timer: timer,
            html: true,
            confirmButtonText: 'OK',
            animation: 'pop'
        });
    }, confirm: function (text, onConfirm, confirmButtonText = 'Usuń') {
        swal({
            title: 'Potwierdzenie',
            text: text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: confirmButtonText,
            cancelButtonText: 'Anuluj',
            closeOnConfirm: false,
            animation: 'pop'
        }, function (isConfirm) {
            if (isConfirm) {
                onConfirm();
            }
        });
    }, refresh: function (text) {
        swal({
            title: 'Ostrzeżenie',
            text: text,
            type: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Odśwież',
            closeOnConfirm: false,
            animation: 'pop'
        }, function (isConfirm) {
            if (isConfirm) {
                location.reload();
            }
        });
    }, confirmAgree: function (text, onConfirm) {
        swal({
            title: 'Potwierdzenie',
            text: text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Tak',
            cancelButtonText: 'Anuluj',
            closeOnConfirm: false,
            animation: 'pop'
        }, function (isConfirm) {
            if (isConfirm) {
                onConfirm();
            }
        });
    }, confirmAgreeHTML: function (html, onConfirm) {
        swal({
            title: 'Potwierdzenie',
            text: html,
            html: true,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Tak',
            cancelButtonText: 'Anuluj',
            closeOnConfirm: false,
            animation: 'pop'
        }, function (isConfirm) {
            if (isConfirm) {
                onConfirm();
            }
        });
    }, confirmInput: function (text, onConfirm) {
        swal({
            title: 'Potwierdzenie',
            text: text,
            type: 'input',
            showCancelButton: true,
            confirmButtonText: 'Wyślij',
            cancelButtonText: 'Anuluj',
            closeOnConfirm: false,
            animation: 'pop'
        }, function (inputValue) {
            if (inputValue === false) return false;
            if (inputValue === '') {
                swal.showInputError('Pole wymagane.');
                return false;
            }

            onConfirm(inputValue);
        });
        $('.sweet-alert input').removeAttr('maxlength');
    }, pin: function (onConfirm, loaderEnabled = true) {
        var pin = Methods.storage.get('user_pin');

        if (pin) {
            if (loaderEnabled) Loader.on();
            onConfirm(pin);
        } else {
            // $('.pin-confirmation').prepend('<input type="password" name="password" class="hidden" style="display: none"/>');

            swal({
                title: 'Podaj PIN',
                text: '',
                type: 'input',
                showCancelButton: true,
                closeOnConfirm: false,
                confirmButtonText: 'OK',
                cancelButtonText: 'Anuluj',
                animation: 'pop',
                inputType: 'text',
                customClass: 'pin-confirmation',
            }, function (pin) {
                if (pin === false) return false;
                if (pin === '') {
                    swal.showInputError('Naprawdę musisz podać PIN.');
                    return false;
                }

                Messages.close();

                //save pin for admin user
                if (window.storage.user.role_name === admin_role || window.storage.user.role_name === moderator_role) {
                    Methods.storage.set('user_pin', pin);
                }

                if (loaderEnabled) Loader.on();
                onConfirm(pin);

                setTimeout(function () {
                    $('.pin-confirmation input').removeAttr('maxlength');
                }, 1000);
            });

            $('.pin-confirmation input')
              .attr('maxlength', 4)
              .val('')
              .focus();
        }
    }, close: function () {
        swal.close();
    }
}

var Fiscalize = {
    printReceipt: function (documentData, paymentAmount, paymentRest, callback) {
        products = documentData.items;
        total = documentData.gross;
        payment = documentData.payment;

        var lines = [], taxes = {
            23: 'A', 8: 'B', 0: 'C', 5: 'D', 'zw': 'G'
        };

        var nip = documentData.contractor ? `NIP nabywcy: ${documentData.contractor.invoice_nip}` : '';

        // lines.push('P' + products.length + '$h');
        lines.push('P' + products.length + ';1$h' + nip + '{CR}');

        $.each(products, function (index, product) {
            index = index + 1;

            //prepare name: remove polish characters, remove non-alphanumeric and non-spaces characters, remove double spaces, cut name to 38 length and trim
            name = product.name.escapeDiacritics().replace(/[^0-9a-z\s]/gi, '').replace(/ +(?= )/g, '').substring(0, 38).trim();
            tax = taxes[product.vat_rate] ? taxes[product.vat_rate] : 'A';

            line = 'P' + index + '$l' + name + '{CR}' + product.quantity + '{CR}' + tax + '/' + product.price_gross + '/' + product.total_gross + '/';
            lines.push(line);
        });

        if (payment == 'CARD') {
            lines.push('P0;0;1;0;0;1;0;0;0;0$x00A{CR}{CR}{CR}{CR}{CR}{CR}{CR}{CR}{CR}' + total + '/0/' + total + '/' + total + '/0/0/0/0/0/');
        } else {
            if (paymentAmount && paymentRest) {
                paymentAmount = parseFloat(paymentAmount).toFixed(2);
                paymentRest = parseFloat(paymentRest).toFixed(2);

                lines.push('P0;0;1;0;1;0;0;0;0;0$x00A{CR}{CR}{CR}{CR}{CR}{CR}{CR}{CR}{CR}' + total + '/0/' + paymentAmount + '/0/0/0/0/0/' + paymentRest + '/');
            } else {
                lines.push('P0;0;1;0;1;0;0;0;0;0$x00A{CR}{CR}{CR}{CR}{CR}{CR}{CR}{CR}{CR}' + total + '/0/' + total + '/0/0/0/0/0/0/');
            }
        }

        lines = Fiscalize._prepareCommands(lines);

        result = [];
        $.each(lines, function (index, line) {
            result.push(line.join('-'));
        });
        command = result.join('|');
        status = false;

        $.ajax({
            url: 'http://127.0.0.1:8090/print/',
            crossDomain: true,
            type: 'POST',
            dataType: 'json',
            data: {command: command},
            error: function () {
                $.ajax({
                    url: 'http://127.0.0.1:8090/print/',
                    crossDomain: true,
                    type: 'POST',
                    dataType: 'json',
                    data: {command: '1B-50-30-24-65-38-45-1B-5C'}
                });

                // Messages.error('Wystąpił błąd, spróbuj ponownie.');
                Loader.off();
            }
        }).done(function (response) {
            if (response.status) {
                callback();
            } else {
                $.ajax({
                    url: 'http://127.0.0.1:8090/print/',
                    crossDomain: true,
                    type: 'POST',
                    dataType: 'json',
                    data: {command: '1B-50-30-24-65-38-45-1B-5C'}
                });

                // Messages.error('Wystąpił błąd, spróbuj ponownie.');
                Loader.off();
            }
        });
    },

    cancelPrinting: function () {
        $.ajax({
            url: 'http://127.0.0.1:8090/print/',
            crossDomain: true,
            type: 'POST',
            dataType: 'json',
            data: {command: '1B-50-30-24-65-38-45-1B-5C'}
        });
        Fiscalize.makeSound();
    },

    // 1B - space - open and close line
    // 50 - P
    // 24 - $
    // 5C - slash

    makeSound: function () {
        $.ajax({
            url: 'http://127.0.0.1:8090/print/',
            crossDomain: true,
            type: 'POST',
            dataType: 'json',
            data: {command: '1B-07-1B-5C'}
        });
    },

    getStatus: function () {
        $.ajax({
            url: 'http://127.0.0.1:8090/print/',
            crossDomain: true,
            type: 'POST',
            dataType: 'json',
            data: {command: '1B-05-1B-5C'}
        });
    },

    getStatus2: function () {
        $.ajax({
            url: 'http://127.0.0.1:8090/print/',
            crossDomain: true,
            type: 'POST',
            dataType: 'json',
            data: {command: '1B-10-1B-5C'}
        });
    },

    _prepareCommands: function (lines) {
        var result = [];

        for (var i = 0; i < lines.length; i++) {
            hexes = Fiscalize._hexEncode(lines[i]);
            sum = Fiscalize._calculateSum(hexes);

            hexes.unshift('1B');

            hexes = hexes.concat(sum.array);
            hexes.push('1B', '5C');

            result.push(hexes);
        }

        return result;
    },

    _hexEncode: function (string) {
        var result = [];

        //replace new line character to !
        string = string.split('{CR}').join('!');

        for (var i = 0, len = string.length; i < len; i++) {
            if (string[i] == '!') {
                hex = '0D';
            } else {
                hex = string.charCodeAt(i).toString(16).toUpperCase();
            }
            result.push(hex);
        }

        return result;
    },

    _makeHexString: function (hexes) {
        var result = '';
        for (var i = 0, len = hexes.length; i < len; i++) {
            substring = '\\x' + hexes[i].toString(16);
            result += substring;
        }

        return result;
    },

    _calculateSum: function (hexes) {
        var sum = '';
        for (var i = 1, len = hexes.length; i < len; i++) { //ommit first element, it's not necessary for sum
            decimal = parseInt(hexes[i], 16);

            if (sum == '') {
                sum = '255' ^ decimal;
            } else {
                sum = sum ^ decimal;
            }
        }

        hexes = Fiscalize._hexEncode(sum.toString(16).toUpperCase());
        result = {
            array: hexes, string: Fiscalize._makeHexString(hexes)
        }
        return result;
    }
};

$(window).scroll(function () {
    if ($('.heading-a').length) {
        if ($(document).scrollTop() > 51) {
            $('body').addClass('header-a-fixed');
        } else {
            $('body').removeClass('header-a-fixed');
        }
    }

    if ($('.panel-a-header').length) {
        if ($(document).scrollTop() > 46) {
            $('body').addClass('header-panel-a-fixed');
        } else {
            $('body').removeClass('header-panel-a-fixed');
        }
    }

    if ($('.layout-header').length) {
        if ($(document).scrollTop() > 46) {
            $('body').addClass('layout-header-fixed');
        } else {
            $('body').removeClass('layout-header-fixed');
        }
    }
});

$(document).ready(function () {
    // $('body').tooltip({
    //     selector: '[rel="tooltip"]'
    // });

    $(document).on('click', '.tab', function (e) {
        e.preventDefault();

        tabPane = $(this).attr('href');
        tabs = $(this).parents('.tabs');

        //
        tabs.find('nav li').removeClass('active');
        $(this).parent().addClass('active');

        //
        tabs.find('.tab-pane').removeClass('active');
        tabs.find(tabPane).addClass('active');
    });

    $(document).on('keypress', '.enter-to-next-field', function (e) {
        if (e.which === 13) {
            var index = $('.form-control').index(this) + 1;
            $('.form-control').eq(index).focus();
        }
    });

    $(document).on('change', '[id$="SelectAll"]', function () {
        tab = $(this).parents('.tab-pane');
        checkboxes = tab.find('input[type="checkbox"]');

        if ($(this).is(':checked')) {
            checkboxes.prop('checked', true);
        } else {
            checkboxes.prop('checked', false);
        }
    });
});

$(document).on('keyup, change', '.form-group.has-error input', function () {
    form_group = $(this).parents('.form-group');
    form_group
      .removeClass('has-error')
      .find('span.help-block').remove();

    $(this).siblings('span.help-block').remove();
});

$(document).on('keypress', '[name^="price"]', function (e) {
    if (e.which == 13) {
        currentIndex = $(this).index();
        $(this).parents('.form-group').nextAll().eq(0).find('input').focus();
    }
});

bootbox.setDefaults({
    locale: 'pl'
});

swal.setDefaults({
    confirmButtonColor: '#864d99', animation: 'slide-from-top'
});

//datepicker locale
$.datepicker.regional['pl'] = {
    monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
    dayNamesShort: ['Nie', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'So'],
    dayNamesMin: ['N', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
    nextText: '»»',
    prevText: '««'
}
$.datepicker.setDefaults($.datepicker.regional['pl']);

//sweet alert tab key bug fix
(function () {
    var close = window.swal.close;
    window.swal.close = function () {
        close();
        window.onkeydown = null;
    };
})();

String.prototype.parseHex = function () {
    return this.replace(/\\x([a-fA-F0-9]{2})/g, function (a, b) {
        return String.fromCharCode(parseInt(b, 16));
    });
};

String.prototype.escapeDiacritics = function () {
    return this.replace(/ą/g, 'a').replace(/Ą/g, 'A')
      .replace(/ć/g, 'c').replace(/Ć/g, 'C')
      .replace(/ę/g, 'e').replace(/Ę/g, 'E')
      .replace(/ł/g, 'l').replace(/Ł/g, 'L')
      .replace(/ń/g, 'n').replace(/Ń/g, 'N')
      .replace(/ó/g, 'o').replace(/Ó/g, 'O')
      .replace(/ś/g, 's').replace(/Ś/g, 'S')
      .replace(/ż/g, 'z').replace(/Ż/g, 'Z')
      .replace(/ź/g, 'z').replace(/Ź/g, 'Z');
}

//Handle barcode scanner
var key_press = {
    last_time: 0, time: 0, total: 0, keys: 0, timeout: null
}
$(document).keypress(function (e) {
    key_press.time = new Date().getTime();

    if (key_press.last_time != 0) {
        key_press.keys++;
        key_press.total += key_press.time - key_press.last_time;
        var speed = Math.round(key_press.keys / key_press.total * 10000, 2);

        if (speed > 200) {
            storage.scanning = true;

            //prevent enter
            if (e.which == 13) {
                e.preventDefault();
            }

        } else {
            storage.scanning = false;
        }
    }

    key_press.last_time = key_press.time;

    //clear
    clearTimeout(key_press.timeout);
    key_press.timeout = setTimeout(function () {
        key_press.last_time = 0;
        key_press.keys = 0;
        key_press.total = 0;

        storage.scanning = false;
    }, 500);
});

// define byString object method
Object.byString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot

    const a = s.split('.');

    for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];

        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }

    return o;
};
